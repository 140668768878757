<template>
  <div>
    <br />
    <v-divider />
    <div class="d-flex mx-2">
      <v-btn
        :disabled="!disableValidation && !valid"
        :dark="disableValidation || valid"
        color="success"
        class="mx-2"
        tile
        depressed
        @click="$emit('save')"
      >
        <v-icon>mdi-content-save</v-icon>
        {{ saveLabel || $t("general.save") }}
      </v-btn>

      <v-btn
        class="mx-2"
        color="primary"
        tile
        depressed
        @click="$emit('reset')"
      >
        <v-icon>mdi-undo-variant</v-icon>
        {{ $t("general.undo") }}
      </v-btn>

      <v-spacer />

      <v-btn
        v-if="!disableValidation"
        tile
        depressed
        @click="$emit('validate')"
      >
        <v-icon>mdi-check</v-icon>
        {{ $t("general.validate") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormBar",
  props: {
    valid: Boolean,
    saveLabel: String,
    disableValidation: Boolean,
  },
};
</script>
