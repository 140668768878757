<template>
  <div>
    <div v-for="(item, index) in layout" :key="index">
      <collapsable-card
        v-if="['card', 'matrix'].includes(item.type)"
        :title="$t(item.titleKey)"
      >
        <template v-if="notEmptyArray(item.rows)">
          <v-row v-for="(row, ri) in item.rows" :key="ri">
            <v-col
              v-for="column in row.columns"
              :cols="column.width"
              :key="column.name"
            >
              <slot :name="column.name"></slot>
            </v-col>
          </v-row>
        </template>
        <template v-if="!!item.groups && !!item.headers && !!item.inputs">
          <v-simple-table fixed-header dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th></th>
                  <th v-for="(header, index) in item.headers" :key="index" class="font-weight-black">
                    {{ $t(header.labelKey) }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(group, groupIndex) in item.groups" :key="groupIndex">
                  <td class="font-weight-black">{{ $t(group.labelKey) }}</td>
                  <td v-for="(header, headerIndex) in item.headers" :key="headerIndex">
                    <slot :name="item.inputs[groupIndex][headerIndex]" :header="header"></slot>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
      </collapsable-card>
      <div v-else>
        <template v-if="notEmptyArray(item.rows)">
          <v-row v-for="(row, ri) in item.rows" :key="ri">
            <v-col
              v-for="column in row.columns"
              :cols="column.width"
              :key="column.name"
            >
              <slot :name="column.name"></slot>
            </v-col>
          </v-row>
        </template>
      </div>

      <!-- <v-row v-else>
        <v-col v-for="column in item.columns" :cols="column.width" :key="column.name">
          <slot :name="column.name"></slot>
        </v-col>
      </v-row> -->
    </div>
  </div>
</template>

<script>
import CollapsableCard from "@/common/components/CollapsableCard";

export default {
  name: "FormLayout",
  components: { CollapsableCard },
  props: {
    layout: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    console.log(this.layout);
  },
};
</script>
