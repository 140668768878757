<template>
  <v-card
    class="mb-5 mt-2"
    :outlined="compact"
    :flat="compact"
    :elevation="!collapseToggled ? 2 : 0"
  >
    <v-toolbar
      flat
      dark
      short
      dense
      :height="compact ? 30 : 40"
      :color="color"
      :style="
        collapseToggled
          ? 'border-bottom-left-radius: inherit; border-bottom-right-radius: inherit; '
          : 'border-bottom-left-radius: unset; border-bottom-right-radius: unset;'
      "
    >
      <v-icon v-if="!!icon">
        {{ icon }}
      </v-icon>
      <v-toolbar-title class="ml-1 mr-1" style="font-size: 13pt;">
        {{ title }}
      </v-toolbar-title>
      <v-spacer />
      <v-btn
        icon
        :small="!compact"
        :x-small="compact"
        v-if="collapsable"
        @click="toggleCollapse"
        class="ma-0"
        color="#ff0087"
      >
        <v-icon color="white">
          {{ collapseToggled ? "mdi-chevron-down" : "mdi-chevron-up" }}
        </v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text v-show="!collapseToggled" :class="compact ? 'pa-0' : 'pt-7'">
      <slot></slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "CollapsableCard",
  data() {
    return {
      collapseToggled: false,
    };
  },
  props: {
    title: {
      required: true,
      default() {
        return "Title...";
      },
    },
    collapsed: Boolean,
    collapsable: {
      type: Boolean,
      default() {
        return true;
      },
    },
    compact: Boolean,
    color: {
      default() {
        return "accent";
      },
    },
    // elevation: {
    //   default() {
    //     return 2;
    //   },
    // },
    icon: String,
  },
  watch: {
    collapsed: {
      handler: function(n) {
        this.collapseToggled = n;
      },
    },
  },
  mounted() {
    this.collapseToggled = this.collapsed;
  },
  methods: {
    toggleCollapse() {
      this.collapseToggled = !this.collapseToggled;
    },
  },
};
</script>
